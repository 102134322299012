import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

  function Square(props) {
    // a function component: simpler way to write a component that only renders
    // turns a component into a function call and returns the html that represents the component

      return (
        <button 
        className="square" 
        onClick={props.onClick}
        style={{backgroundColor:props.bgColor}}
        >
            {props.value}
        </button>
      );
  }
  
  class Board extends React.Component {

    renderSquare(i) {
      return (
          <Square 
          value={this.props.squares[i]}
          onClick={() => this.props.onClick(i)} 
          bgColor={this.props.colors[i]}
          />
      );
    }    
  
    render() {
      const grid = []; // contains the rows of squares that are rendered, as react elements
      
      for (let i = 0; i < 3; i++) {
        const squares = []; // contains the squares that are rendered, as react elements

        for (let j = 0; j < 3; j++) {
          squares.push(this.renderSquare(i * 3 + j));
        }

        grid.push(<div className="board-row">{squares}</div>);
      }

      return ( // parses the grid into html elements
        <div>
           {grid}
        </div>
      );
    }
  }

  class Winner {
    constructor(symbol, squares) {
      this.symbol = symbol;
      this.squares = squares;
      console.log("Winning Squares: " + this.squares);
    }
  }
  
  class Game extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            history: [{
                squares: Array(9).fill(null),
                coordinates: [], 
            }],
            stepNumber: 0,
            xIsNext: true,
        };
    }


    handleClick(i) {
      const history = this.state.history.slice(0, this.state.stepNumber + 1);
      const current = history[history.length - 1];
      const squares = current.squares.slice(); // copies the array and makes the reference immutable
      const coordinates = current.coordinates.slice();


      // return early if there was a winner or the square was already clicked
      if (calculateWinner(squares) || squares[i]) {
          return;
      }

      squares[i] = this.state.xIsNext ? 'X' : 'O'; // mutates the square that was clicked
      coordinates.push([Math.floor(i / 3), i % 3]);
      console.log("Coordinate Slice: " + coordinates[coordinates.length - 1]);

      this.setState({
          // combine arrays into a new array
          history: history.concat([{
            squares: squares,
            coordinates: coordinates
          }]),
          stepNumber: history.length,
          xIsNext: !this.state.xIsNext,
      }); // replaces the boards reference with this constant one
    }

    jumpTo(step) {
      this.setState({
        stepNumber: step,
        xIsNext: (step % 2) === 0,
      });
    }

    render() {
      const history = this.state.history;
      const current = history[this.state.stepNumber];
      const winner = calculateWinner(current.squares); // contains the symbol of the winner, X or O 
      const colors = Array(9).fill(null);
      // when a button is clicked, all buttons are rendered again, it seems like
      // the button that I click on is the button that gets bolded
      // React is only supposed to rerender elements that have changed, though
      // this part is confusing me...
      // oh, wait, maybe it compares what I generate here to the ReactDOM later
      // so my console call is technically recording elements here but it only renders what's changed in the virtual DOM
      // which there would only be two buttons that would change: the new bolded one and the one that needs to go back to normal

      const moves = history.map((step, move) => {
        const font = (move === this.state.stepNumber ? "bold" : "normal");
        const desc = move ? 
          'Go to move #' + move + ': (' + step.coordinates[move - 1] + ')' :
          'Go to game start';

        console.log(move);
        console.log(step.coordinates);

        return (
          <li key={move}>
            <button onClick={() => this.jumpTo(move) } style={{fontWeight:font}} >
              {desc}
            </button>
          </li>
        );
      });

      let status;
      if (winner) { // only being check if it's not null
        status = 'Winner: ' + winner.symbol;
        winner.squares.forEach(square => {
          console.log("Winning Square: " + square)
          colors[square] = "grey";
          console.log("Colors: " + colors);
        })
      } else if (this.state.stepNumber >= 9) {
        status = 'Draw!';
      } else {
        status = 'Next Player: ' + (this.state.xIsNext ? 'X' : 'O');
      }
      
      return (
        <div className="game">
          <div className="game-board">
            <Board
            squares={current.squares}
            onClick={(i) => this.handleClick(i)} 
            colors={colors}
            />
          </div>
          <div className="game-info">
            <div>{ status }</div>
            <ol>{ moves }</ol>
          </div>
        </div>
      );
    }
  }
  
  function calculateWinner(squares) {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        // return squares[a];
        return new Winner(squares[a], [a, b, c]);
      }
    }
    return null;
  }

  // ========================================
  
  ReactDOM.render(
    <Game />,
    document.getElementById('root')
  );
  
  